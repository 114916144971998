import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
// @ts-nocheck
import { onMounted, reactive, ref, computed, shallowRef, inject } from "vue";
import { baseGet, webApiGet, aiquaBanner, isApiDev } from "@/js/services/baseService";
export default {
  props: {
    full: {
      type: Boolean,
      default: false
    },
    list: Array,
    bannerType: String
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var refreshMemberInfo = inject("refreshMemberInfo");

    var _refreshMemberInfo = refreshMemberInfo(),
        isPro = _refreshMemberInfo.isPro,
        isLogin = _refreshMemberInfo.isLogin,
        memberInfo = _refreshMemberInfo.memberInfo;

    var confirmRedirect = inject("confirmRedirect");
    var pageData = reactive({
      list: []
    });

    switch (props.bannerType) {
      case "Pray":
        baseGet("/api_lucky_pray/lucky_banner_list", {}).then(function (res) {
          pageData.list = res.data.res_data.banner_list;
        });
        break;

      case "Home":
        baseGet("/api_front_page/banner", {}).then(function (res) {
          pageData.list = res.data.res_data.banner; // 加上APPIER橫幅數量限制(Home > 6)

          var appierBannerNum = 0;
          var appierBannerLimit = 6;
          webApiGet("/api/APP/index/item00004/getResult.php?requestType=1").then(function (res) {
            var _pageData$list;

            (_pageData$list = pageData.list).push.apply(_pageData$list, _toConsumableArray(res.data.map(function (item) {
              return {
                url: item.banner_link,
                pic_src: item.banner_img
              };
            }))); // biBit Banner
            // if (localStorage.getItem('app_cid')) {


            var tid = 'OA-50b80f';

            if (!isApiDev) {
              tid = 'OA-50b80f';
            }

            var cid = localStorage.getItem('app_cid') || "";
            var uid = '';

            if (localStorage.getItem('appier_id')) {
              uid = localStorage.getItem('appier_id');
            }

            var product_tag_names = "600x360";
            var product_category_names = "";
            var recommend_type = 'user_item_embedding';
            var num = appierBannerLimit;
            var sorting = "random";
            $.get("https://omnisegment.com/ai-recommendation/get-ai-recommendation?tid=".concat(tid, "&cid=").concat(cid, "&uid=").concat(uid, "&num=").concat(num, "&recommend_type=").concat(recommend_type, "&product_tag_names=").concat(product_tag_names, "&product_category_names=").concat(product_category_names, "&sorting=").concat(sorting), {}, function (data) {
              if (data.SUCCESS) {
                var items = data.PAYLOAD;
                $.each(items, function (k, row) {
                  appierBannerNum += 1;

                  if (appierBannerLimit >= appierBannerNum) {
                    var pic_src = '';
                    var url = addVender(row.product_url, 'home');
                    $.each(row.additional_photo_url, function (k, item) {
                      if (item.name == "600x360") {
                        pic_src = item.link;
                      }
                    });
                    pageData.list.push({
                      url: url,
                      pic_src: pic_src
                    });
                  }
                });
              }
            }); // }
            // if (/(AndroidMuki)/i.test(window.navigator.userAgent)) {
            //     // 首頁要埋推薦碼
            //     aiquaBanner("ad8p8xefDLYZiqsm9cuStJ", (items) => {
            //         // items.sort(() => Math.random() - 0.5);
            //         // ori Wkk9gJEwLHJxxAL7kisvZa
            //         items.map((row) => {
            //             appierBannerNum += 1; 
            //             if (appierBannerLimit >= appierBannerNum) {
            //                 let url = addVender(row.url,'home');
            //                 pageData.list.push({
            //                     url: url,
            //                     pic_src: row.customLabel01,
            //                 });
            //             }
            //         });
            //     });
            // } else if (/(IosMuki)/i.test(window.navigator.userAgent)) {
            //     // 首頁要埋推薦碼
            //     aiquaBanner("HnuYhwkW2ghQ7b6DkrsTLv", (items) => {
            //         // items.sort(() => Math.random() - 0.5);
            //         // ori AgxjN3ni5yvvjZoVwJdJvg
            //         items.map((row) => {
            //             appierBannerNum += 1; 
            //             if (appierBannerLimit >= appierBannerNum) {
            //                 console.log('appierBannerLimit',appierBannerLimit);
            //                 console.log('appierBannerNum',appierBannerNum);
            //                 let url = addVender(row.url,'home');
            //                 pageData.list.push({
            //                     url: url,
            //                     pic_src: row.customLabel01,
            //                 });
            //             }
            //         });
            //     });
            // }
          });
        });
        break;

      case "Ziwei":
        webApiGet("/api/APP/index/item00004/getResult.php?requestType=3").then(function (res) {
          pageData.list = res.data.map(function (item) {
            return {
              url: item.banner_link,
              pic_src: item.banner_img
            };
          });
        });
        break;

      case "Fortune":
        webApiGet("/api/APP/index/item00004/getResult.php?requestType=7").then(function (res) {
          pageData.list = res.data.map(function (item) {
            return {
              url: item.banner_link,
              pic_src: item.banner_img
            };
          });
        });
        break;

      case "Japan":
        webApiGet("/api/APP/index/item00004/getResult.php?requestType=10").then(function (res) {
          pageData.list = res.data.map(function (item) {
            return {
              url: item.banner_link,
              pic_src: item.banner_img
            };
          }); // 加上APPIER橫幅數量限制(Japan > 10)

          var appierBannerNum = 0;
          var appierBannerLimit = 10; // biBit Banner
          // if (localStorage.getItem('app_cid')) {

          var tid = 'OA-50b80f';

          if (!isApiDev) {
            tid = 'OA-50b80f';
          }

          var cid = localStorage.getItem('app_cid') || "";
          var uid = '';

          if (localStorage.getItem('appier_id')) {
            uid = localStorage.getItem('appier_id');
          }

          var product_tag_names = "600x360";
          var product_category_names = "日本命理";
          var recommend_type = 'user_item_embedding';
          var num = appierBannerLimit;
          var sorting = "random";
          $.get("https://omnisegment.com/ai-recommendation/get-ai-recommendation?tid=".concat(tid, "&cid=").concat(cid, "&uid=").concat(uid, "&num=").concat(num, "&recommend_type=").concat(recommend_type, "&product_tag_names=").concat(product_tag_names, "&product_category_names=").concat(product_category_names, "&sorting=").concat(sorting), {}, function (data) {
            if (data.SUCCESS) {
              var items = data.PAYLOAD;
              $.each(items, function (k, row) {
                appierBannerNum += 1;

                if (appierBannerLimit >= appierBannerNum) {
                  var pic_src = '';
                  var url = addVender(row.product_url, 'japan');
                  $.each(row.additional_photo_url, function (k, item) {
                    if (item.name == "600x360") {
                      pic_src = item.link;
                    }
                  });
                  pageData.list.push({
                    url: url,
                    pic_src: pic_src
                  });
                }
              });
            }
          }); // }
          // if (/(AndroidMuki)/i.test(window.navigator.userAgent)) {
          //     // 首頁要埋推薦碼
          //     aiquaBanner("ZPToJQ6Qj6HHNgsRkEc6Yd", (items) => {
          //         // ori ACYnMV3irfGRByRSgPFvFL
          //         items.map((row) => {
          //             appierBannerNum += 1; 
          //             if (appierBannerLimit >= appierBannerNum) {
          //                 let url = addVender(row.url,'japan');
          //                 pageData.list.push({
          //                     url: url,
          //                     pic_src: row.customLabel01,
          //                 });
          //             }
          //         });
          //     });
          // } else if (/(IosMuki)/i.test(window.navigator.userAgent)) {
          //     // 首頁要埋推薦碼
          //     aiquaBanner("5SywgmzQ3357TPvSSZDL5V", (items) => {
          //         // ori 7wupid6s5rS3VQAkBuEk4r
          //         items.map((row) => {
          //             appierBannerNum += 1; 
          //             if (appierBannerLimit >= appierBannerNum) {
          //                 let url = addVender(row.url,'japan');
          //                 pageData.list.push({
          //                     url: url,
          //                     pic_src: row.customLabel01,
          //                 });
          //             }
          //         });
          //     });
          // }
        });
        break;
    }

    function addVender(url, type) {
      var vender = '';

      if (type == 'japan') {
        vender = 'beBit_app_jpfate_carousel';
      } else {
        vender = 'beBit_app_index_carousel';
      }

      if (url.includes("?")) {
        url += "&Vender=".concat(vender);
      } else {
        url += "?Vender=".concat(vender);
      }

      return url;
    }

    return {
      confirmRedirect: confirmRedirect,
      pageData: pageData
    };
  }
};