import { computed } from "vue";
export default {
  props: {
    type: {
      type: String,
      default: 'button'
    },
    href: String,
    // 高度
    h: {
      type: [String],
      default: ''
    },
    // 高度 /
    // radius
    radius: Boolean,
    // pill 樣式
    pill: Boolean,
    // pill 樣式 /
    // login register頁用
    loginRegister: Boolean,
    // 陰影
    shadow: Boolean,
    // 透明
    textOpacity: Boolean,
    // borderWidth
    borderWidth: String,
    // 大小
    lg: Boolean,
    // block 、 inline
    inline: Boolean
  },
  setup: function setup(props, context) {
    return {
      m_direct_page: m_direct_page
    };
  }
};