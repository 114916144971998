import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { computed, ref, onMounted, reactive, shallowRef, inject, watch } from "vue";
import { baseGet, basePost, Store } from "@/js/services/baseService";
import FortuneBlock from "@projectComponents/everyday_fortune/FortuneBlock";
import MainStarBlock from "@projectComponents/everyday_fortune/MainStarBlock";
import HoroscopeBlock from "@projectComponents/everyday_fortune/HoroscopeBlock";
import StickBlock from "@projectComponents/everyday_fortune/StickBlock";
import FateHexagramBlock from "@projectComponents/everyday_fortune/FateHexagramBlock";
import TarotLoveBlock from "@projectComponents/everyday_fortune/TarotLoveBlock";
export default {
  props: {
    header: String
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var confirmRedirect = inject("confirmRedirect");
    var refreshMemberInfo = inject("refreshMemberInfo");

    var _refreshMemberInfo = refreshMemberInfo(),
        isPro = _refreshMemberInfo.isPro,
        isLogin = _refreshMemberInfo.isLogin,
        memberInfo = _refreshMemberInfo.memberInfo;

    var formData = reactive({
      name: "",
      birthday: "",
      time: "",
      gender: "woman",
      checkedType: "",
      code: "",
      familyName: "",
      givenName: ""
    }); // formData.name = memberInfo.nick_name;
    // formData.gender = memberInfo.gender;

    var contentType = reactive({
      NameBlock: true,
      // 今日運勢
      FortuneBlock: shallowRef(FortuneBlock),
      // 命宮主星
      MainStarBlock: shallowRef(MainStarBlock),
      // 紫微命盤
      HoroscopeBlock: shallowRef(HoroscopeBlock),
      // 塔羅愛情
      // TarotLoveBlock: shallowRef(TarotLoveBlock),
      // 九星命掛
      FateHexagramBlock: shallowRef(FateHexagramBlock) // 好運求籤
      // StickBlock: shallowRef(StickBlock),

    });
    var fortuneTypes = reactive({
      data: []
    }); // 從後台設置幾個區塊的標題

    baseGet("/api_front_page/free_list", {}).then(function (res) {
      fortuneTypes.data = res.data.res_data;
      doAction(fortuneTypes.data[0]);
    });
    var timeData = reactive({
      list: ['00:00 - 00:59(早子)', '01:00 - 02:59(丑)', '03:00 - 04:59(寅)', '05:00 - 06:59(卯)', '07:00 - 08:59(辰)', '09:00 - 10:59(巳)', '11:00 - 12:59(午)', '13:00 - 14:59(未)', '15:00 - 16:59(申)', '17:00 - 18:59(酉)', '19:00 - 20:59(戌)', '21:00 - 22:59(亥)', '23:00 - 23:59(晚子)'],
      moreList: ['00:00 - 00:59(早子)', '01:00 - 02:59(丑)', '03:00 - 04:59(寅)', '05:00 - 06:59(卯)', '07:00 - 08:59(辰)', '09:00 - 10:59(巳)', '11:00 - 12:59(午)', '13:00 - 14:59(未)', '15:00 - 16:59(申)', '17:00 - 18:59(酉)', '19:00 - 20:59(戌)', '21:00 - 22:59(亥)', '23:00 - 23:59(晚子)', '不知道時辰']
    });
    watch(function () {
      return formData.code;
    }, function (val) {
      if (val !== 'NameBlock' && formData.time === '不知道時辰') {
        formData.time = '';
      }
    });

    function doAction(item) {
      formData.code = "";
      formData.checkedType = item.topic;

      if (item.url_link) {
        confirmRedirect(item.topic + "", item.url_link);
      } else {
        formData.code = item.code;
      }
    }

    var changeGender = function changeGender() {
      formData.gender = formData.gender == "woman" ? "man" : "woman";
    };

    var toSubmit = function toSubmit() {
      if (!formData.name || !formData.birthday || !formData.time || !formData.code) {
        f7.dialog.confirm("請填寫所有必填項目喔", "", function () {});
      } else {
        var birth = _toConsumableArray(formData.birthday).reduce(function (all, row) {
          return all + (row != "年" && row != "月" && row != "日" ? row : ".");
        }).split(".");

        var url = "";

        switch (formData.code) {
          case "FortuneBlock":
            url = "https://ms.click108.com.tw/unit001/free00001/result.php?free001Area=2&Vender=app_index_free_today";
            break;

          case "MainStarBlock":
            url = "https://ms.click108.com.tw/unit001/free00001/result.php?free001Area=1&Vender=app_index_free_palace";
            break;

          case "HoroscopeBlock":
            url = "https://wwwstage.click108.com.tw/unit001/free-ziwei/result.php?Vender=app_index_free_ziwei";
            break;

          case "FateHexagramBlock":
            /**
             * 20240110 修改轉換網址
             * https://ms.click108.com.tw/unit001/item00191/trial.php?Vender=app_index_free_9star
             * https://wwwstage.click108.com.tw/unit001/item00251/free.php?Vender=app_index_free_it251
             */
            url = "https://wwwstage.click108.com.tw/unit001/item00251/free.php?Vender=app_index_free_it251";
            break;
        }

        confirmRedirect("", url, false, {
          NickName: formData.name,
          Calendar: 1,
          iYear: birth[0].trim(),
          iMonth: birth[1].trim().padStart(2, "0"),
          iDay: birth[2].trim().padStart(2, "0"),
          iHour: formData.time.slice(0, 2),
          iSex: formData.gender == "woman" ? 0 : 1
        });
      }
    };

    function checkChinese(data) {
      return /[\u4E00-\u9FFF]+$/.test(data);
    }

    var toSubmitName = function toSubmitName() {
      if (!formData.familyName || !formData.givenName || !formData.birthday || !formData.time) {
        f7.dialog.confirm("請填寫所有必填項目喔", "", function () {});
      } else if (!checkChinese(formData.familyName)) {
        f7.dialog.confirm("您的姓氏：格式錯誤，僅可輸入中文字！", "", function () {});
      } else if (!checkChinese(formData.givenName)) {
        f7.dialog.confirm("您的名字：格式錯誤，僅可輸入中文字！", "", function () {});
      } else {
        var birth = _toConsumableArray(formData.birthday).reduce(function (all, row) {
          return all + (row != "年" && row != "月" && row != "日" ? row : ".");
        }).split(".");

        var url = "https://wwwstage.click108.com.tw/unit001/free-name/result.php?Vender=app_index_free_free-name";
        confirmRedirect("", url, false, {
          FamilyName: formData.familyName,
          GivenName: formData.givenName,
          Calendar: 1,
          iYear: birth[0].trim(),
          iMonth: birth[1].trim().padStart(2, "0"),
          iDay: birth[2].trim().padStart(2, "0"),
          iHour: formData.time === '不知道時辰' ? '-1' : formData.time.slice(0, 2),
          iSex: formData.gender == "woman" ? 0 : 1
        });
      }
    };

    return {
      formData: formData,
      timeData: timeData,
      contentType: contentType,
      fortuneTypes: fortuneTypes,
      doAction: doAction,
      changeGender: changeGender,
      toSubmit: toSubmit,
      toSubmitName: toSubmitName
    };
  }
};