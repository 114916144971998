import { computed, ref, onMounted, reactive } from "vue";
export default {
  props: {
    toolbarList: Array,
    page: String
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var cData = reactive({});
    return {
      cData: cData
    };
  }
};