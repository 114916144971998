import { ref, reactive, computed, watch } from "vue";
import { useField } from "vee-validate";
export default {
  name: "BaseInput",
  emits: ["update:modelValue"],
  props: {
    // for icon
    icon: String,
    f7: String,
    // for input
    type: {
      type: String,
      default: "text"
    },
    inputClass: {
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    inputId: String,
    name: String,
    inputmode: String,
    modelValue: [String, Number]
  },
  setup: function setup(props, context) {
    var isShowPassword = ref(false);
    var emit = context.emit;
    var name = props.name,
        modelValue = props.modelValue; // validate

    var _useField = useField(name),
        errorMessage = _useField.errorMessage,
        thisName = _useField.name;

    function emitVal(e) {
      emit("update:modelValue", e.target.value);
    }

    return {
      emitVal: emitVal,
      errorMessage: errorMessage,
      isShowPassword: isShowPassword
    };
  }
};