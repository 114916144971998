import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useForm } from "vee-validate";
import { baseGet, postLogin } from "@/js/services/baseService";
export default {
  setup: function setup(props) {
    var pageData = reactive({
      list: ['instructions.png', 'instructions2.png']
    }); // 從後台設置幾個區塊的標題

    baseGet("/api_common/instructions", {}).then(function (res) {
      pageData.list = res.data.res_data;
    });
    return {
      pageData: pageData
    };
  }
};