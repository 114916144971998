// Import Vue
import { createApp } from 'vue';

// Import Framework7
import Framework7 , {Dom7} from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents, f7, f7ready } from 'framework7-vue/bundle';

import store from '@/store/store.js';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Stylesnpm
import '@/css/icons.css';
import '@/css/app.scss';

// Import App Component
import App from "@/App.vue";

// 表單驗證
import "@/js/validations.js";

// i18n
import { i18n } from "@/js/i18n.js";

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// App 常數
import mukiConst from "@/js/const.js";


// Init App
const app = createApp(App);

window.app = app;
window.mukiConst = mukiConst

// store
app.use(store)

// Register Framework7 Vue components
registerComponents(app);

// F7 可使用時
f7ready(() => {
    // Framework7's initialized instance
    window.f7 = f7;
    window.$$ = Dom7;
})

app.use(i18n);

// Mount the app
app.mount('#app');