import { computed, ref, onMounted, reactive, inject } from "vue";
import { baseGet, basePost, Store } from "@/js/services/baseService";
export default {
  props: {
    header: String
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var confirmRedirect = inject('confirmRedirect');
    var pageData = reactive({
      data: []
    }); // 從後台設置幾個區塊的標題

    baseGet("/api_front_page/alone_list", {}).then(function (res) {
      pageData.data = res.data.res_data.alone_list;
    });
    return {
      pageData: pageData,
      confirmRedirect: confirmRedirect
    };
  }
};