
import { computed } from "vue";
import axios from "axios";
import store from '../../store/store';
import { i18n } from "@/js/i18n.js";

// Flag
let isTokenError = false;

let isApiDev = computed(() => {
    let mode = 1;
    switch (location.host) {
        case 'appv2.click108.com.tw':
            mode = 0;
            break
    }
    return mode;
})

const aiquaBanner = async (recommendation_id = '', cb) => {
    let products = [];
    qg(
        'getRecommendationByScenario',
        {
            scenarioId: recommendation_id,
            user_id: computed(() => {
                return localStorage.getItem('appier_id') ? localStorage.getItem('appier_id') : ''
            }).value,
        },
        function (err, response) {

            if (err !== null) {
                err = err + `,scenarioId:${recommendation_id},user_id:${localStorage.getItem('appier_id') ?? ''}`;
                $.post("/ajax/ajax_mobile/ajax_err_log", {
                    'log':err
                })
            }

            products = response;
            if (products.length > 0) {

                cb(products);

                let urlObj = new URL(products[0]['url']);
                let model_id = urlObj.searchParams.get('rec_mid');
                let recommendation_id = urlObj.searchParams.get('rec_id');
                let scenario_id = urlObj.searchParams.get('rec_sid');

                qg("event", "recommendation_displayed", {
                    model_id: model_id,
                    scenario_id: scenario_id,
                    recommendation_id: recommendation_id,
                    product_ids: products.map(row => row.productId)
                });
            }
        }
    );
}

const apiBase = axios.create({
    baseURL: "/api/",
    withCredentials: true,
    headers: {
        // Accept: "application/json",
        'Content-Type': 'application/form-data'
    },
    transformRequest: [
        function (data, headers) {
            return objToFormData(data)
        }
    ],
    transformResponse: [
        function (res) {
            try {

                var parsed = JSON.parse(res)
                return parsed;

            } catch (err) {
                // 此錯誤不跳顯示
                if(this.url === '/api/APP/index/item00004/getResult.php?requestType=24') {
                    return;
                }
                f7.dialog.alert('資料發生錯誤', '', () => {
                });
            }
        }
    ],
});

apiBase.interceptors.response.use(function (config) {
    const { data } = config;
    return config;
}, function (error) {
    // Do something with request error
    if (ErrorMsgDisableHandleArr.indexOf(error.config.url) === -1) {
        if (error.response) {
            // server responded status code falls out of the range of 2xx
            switch (error.response.status) {
                case 400:
                    if (error.response.data && error.response.data.Message) {
                        f7.dialog.alert(error.response.data.Message, "訊息提示", () => {
                        });
                    } else {
                        f7.dialog.alert(`${error.response.status}: 資料來源不存在`, "訊息提示", () => {
                        });
                    }

                    break;
                case 404:
                    if (error.response.data && error.response.data.Message) {
                        f7.dialog.alert(error.response.data.Message, "訊息提示", () => {
                        });
                    } else {
                        f7.dialog.alert(`${error.response.status}: 資料來源不存在`, "訊息提示", () => {
                        });
                    }
                    break
                case 500:
                    if (error.response.data && error.response.data.Message) {
                        f7.dialog.alert(error.response.data.Message, "訊息提示", () => {
                        });
                    } else {
                        f7.dialog.alert(`${error.response.status}: 內部系統發生錯誤`, "訊息提示", () => {
                        });
                    }
                    break
                default:
                    if (error.response.data && error.response.data.Message) {
                        f7.dialog.alert(error.response.data.Message, "訊息提示", () => {
                        });
                    } else {
                        f7.dialog.alert(`${error.response.data.Message}`, "訊息提示", () => {
                        });
                    }
                    break
            }
        } else {
            // Something happened in setting up the request that triggered an Error
            if (error.code === 'ECONNABORTED' && error.message && error.message.indexOf('timeout') !== -1) {
                // request time out will be here
                f7.dialog.alert('網路連線逾時，請點「確認」鍵後繼續使用。', "訊息提示", () => {
                });
            } else {
                // shutdonw api server
                f7.dialog.alert('網路連線不穩定，請稍候再試', "訊息提示", () => {
                });
            }
        }
    }

    return Promise.reject(error);
});

const setApiMode = (apiBase, mode) => {
    apiBase.defaults.baseURL = "/api/";

    if (mode === 'webApi') {
        apiBase.defaults.baseURL = "/";
        if (window.location.port != '8080' && window.location.port != '8081') {
            if (isApiDev.value) {
                apiBase.defaults.baseURL = "https://wwwstage.click108.com.tw/";
            } else {
                apiBase.defaults.baseURL = "https://www.click108.com.tw/";
            }
        }
    }

    return apiBase;
}

function webApiGet(url, formInfo) {
    return setApiMode(apiBase, 'webApi').get(url, formInfo);
}

function webApiPost(url, formInfo) {
    return setApiMode(apiBase, 'webApi').post(url, formInfo);
}

function baseGet(url, formInfo, mode) {
    return setApiMode(apiBase, mode).get(url, formInfo);
}

function basePost(url, formInfo, mode) {
    return setApiMode(apiBase, mode).post(url, formInfo);
}

function basePut(url, formInfo, mode) {
    return setApiMode(apiBase, mode).put(url, formInfo);
}

function basePatch(url, formInfo, mode) {
    return setApiMode(apiBase, mode).patch(url, formInfo);
}

function baseDel(url, formInfo, mode) {
    return setApiMode(apiBase, mode).delete(url, formInfo);
}

// common
const Common = {
    /**
     * 登入
     * @param {Object} formInfo
     * @property {string} formInfo.account - 帳號 (mukidev)
     * @property {string} formInfo.password - 密碼 (123456)
     * 
     * @return {Object} response
     * @property {string} response.token - 登入類型 (qq11223455)
     * @property {string} response.member_number - 會員編號 (LA000012)
     */
    login(formInfo, mode) {
        return basePost('/api_common/login', formInfo, mode).then(res => {
            return res;
        })
    },
}

export {
    baseGet,
    webApiGet,
    basePost,
    basePut,
    basePatch,
    baseDel,
    webApiPost,

    isApiDev,
    aiquaBanner,
    // ==== API ====
    // Common
    Common,
};
