import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "btn-inner"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_f7_button = _resolveComponent("f7-button");

  return _openBlock(), _createBlock(_component_f7_button, {
    type: $props.type,
    onClick: _cache[0] || (_cache[0] = function ($event) {
      $props.href ? $setup.m_direct_page($props.href) : '';
    }),
    class: _normalizeClass({
      'sty-pill': $props.pill ? true : null,
      'sty-login-register': $props.loginRegister ? true : null,
      'sty-lg': $props.lg ? true : null,
      'sty-text-opacity': $props.textOpacity ? true : null,
      'shadow': $props.shadow ? true : null,
      'button_radius': $props.radius ? true : null
    }),
    style: _normalizeStyle({
      '--f7-button-height': $props.h ? $props.h : null,
      '--button-border-wdith': $props.borderWidth ? $props.borderWidth : null
    })
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default")])];
    }),
    _: 3
  }, 8, ["type", "class", "style"]);
}