import { defineRule, configure } from "vee-validate";
import { confirmed, email, numeric, required } from '@vee-validate/rules';
import { i18n } from "@/js/i18n.js";
import { localize, setLocale } from '@vee-validate/i18n';
import { f7 } from "framework7-vue";
import store from "@/store/store.js";

configure({
    // Generates an English message locale generator
    generateMessage: localize('tw', {
        messages: {
            required: "必填",
            email: '請輸入正確的信箱格式',
            confirmed: '輸入值不相符',
            phoneCheck: '欄位不符合格式，僅接受數字及「-」',
            verifyMobile: '需為數字及開頭為09、含10個字元',
            verifyIdentify: '驗證碼錯誤',
            confirmedPwd: '密碼不一致，請重新輸入',
            verifyEmail: '請輸入正確的信箱格式',
            numeric: '請輸入數值',
        },
    }),
    generateMessage: localize('en', {
        messages: {
            required: 'required',
            email: 'Please enter the correct mailbox format',
            confirmed: 'Input value does not match',
            phoneCheck: 'The field only numbers and "-" are accepted',
            verifyMobile: 'Must be a number and start with 09, containing 10 characters',
            verifyIdentify: 'Verification code error',
            confirmedPwd: 'Passwords are inconsistent, please re-enter',
            verifyEmail: 'Please enter the correct mailbox format',
            numeric: 'Please enter a number',
        },
    }),
});

setLocale(localStorage.getItem('MukiLang') || store.state.locale);

// 官方堤共的 rule
defineRule('email', email);
defineRule('confirmed', confirmed);
defineRule('numeric', numeric);
defineRule('required', required);


// 驗證電話格式
defineRule("phoneCheck", (value) => {
    var re = new RegExp('^[\\d\-]{1,}$');
    if (!value) return true;
    if ( re.test(value) ) return true;

    return false;
});

// 驗證手機格式
defineRule("verifyMobile", (value) => {
    if (/^[09]{2}[0-9]{8}$/.test(value)) return true;

    return false;
});

// 必填
defineRule("required", (value) => {
    if (!value && value !== 0) {
        return "必填";
    }
    return true;
});