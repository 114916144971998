import { f7 } from "framework7-vue";
import { createStore, createLogger } from 'vuex'
import { i18n } from "@/js/i18n.js";
import modules from './modules' // 引入modules

const store = createStore({
  modules, // 放到這裡
  state() {
    return {
      // 語系
      locale: null,
    }
  },
  getters: {
    
  },
  actions: {
    // 初始
    init({commit}) {
      var locale = localStorage.getItem("MukiLang");
      // 語系
      if (locale) {
        commit('updateLocale', locale)
      } else {
        commit('updateLocale', mukiConst.locale['TW'])
      }
    },
  },
  mutations: {
    // 更新語系
    updateLocale(state, langStr) {

      var locale = null;

      switch (langStr) {
        case mukiConst.locale['TW']:
            locale = mukiConst.locale['TW'];
          break;
        case mukiConst.locale['EN']:
            locale = mukiConst.locale['EN'];
          break;
        default:
          break;
      }

      if ( typeof i18n !== "undefined" ) {
        if ( i18n.global.locale.value ) {
          i18n.global.locale.value = locale;
        }
      }
      
      if ( locale ) {
        state.locale = locale
        localStorage.setItem("MukiLang", locale);
      }

    },
  },
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
})
export default store;
