import { computed, ref, onMounted, reactive } from "vue";
export default {
  props: {
    value: String,
    aSwitch: String,
    bSwitch: String
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var cData = reactive({});

    function changeValue() {
      emit("changeValue");
    }

    return {
      cData: cData,
      changeValue: changeValue
    };
  }
};