import { computed, reactive, ref } from "vue";
import { baseGet, webApiPost } from "@/js/services/baseService";

let memberDataList = [
    "serialno",
    "ValidatedKey",
    "ExpiredDate",
    "is_vip",
    "point",
    "coupon_num",
    "is_course_member",
    "is_daily_member",
    "master_star",
    "member_type",
    "newsletter",
    "nick_name",
    "purchase_record",
    "zodiac_sign",
    "member_type_str",
    "vip_expire_date",
    "destiny_analysis",
    "destiny_current_state",
    "fortune_analysis",
    "career_analysis",
    "emotion_analysis",
    "relationship_analysis",
    "vip_attachment",
    "tool",
    "vip_equity",
    "emotion_type",
    "child_type",
    "career_type",
    "isex",
    "birthday_day",
    "birthday_hour",
    "birthday_month",
    "birthday_year",
    "birthday_calendar",
    "birthday_LeapMonth",
    "ga_data",
    "appier_id"
];

export default {
    namespaced: true,
    state: () => ({
        serialno: "",
        ValidatedKey: "",
        ExpiredDate: "",
        is_vip: 0,
        point: 0,
        coupon_num: 0,
        is_course_member: 0,
        is_daily_member: 0,
        master_star: "",
        member_type: "",
        newsletter: "",
        nick_name: "",
        purchase_record: "",
        zodiac_sign: "",
        member_type_str: "一般會員",
        vip_expire_date: "",
        destiny_analysis: "",
        fortune_analysis: "",
        career_analysis: "",
        emotion_analysis: "",
        relationship_analysis: "",
        destiny_current_state: "",
        vip_attachment: "",
        tool: "",
        vip_equity: "",
        emotion_type: 0,
        child_type: 0,
        career_type: 0,
        isex: 0,
        birthday_day: '',
        birthday_hour: '',
        birthday_month: '',
        birthday_year: '',
        birthday_calendar: '',
        birthday_LeapMonth: '',
        first_time_install: 1,
        ga_data: '',
        appier_id: '',
    }),
    getters: {
        // [0: 未登入, 1: 一般會員, 2: 白金會員]
        isLogin(state, getters) {
            return state.serialno != "";
        },
        isPro(state, getters) {
            return state.is_vip == 1;
        },
        // 會員詳細資料
        memberInfo(state, getters) {

            if (state.member_type) {
                state.member_type_str = state.member_type;
            } else {
                if (state.is_vip == 1) {
                    state.member_type_str = "白金會員";
                }
            }

            return reactive(state)
        }
    },
    actions: {
        login({ commit }, data) {
            commit("updateMemberData", data);
        },
        setMemberInfo({ commit, state }, data) {
            // 如果會員目前是有登入的
            if (localStorage.getItem('serialno')) {

                memberDataList.forEach((name) => {
                    let data = localStorage.getItem(name);
                    if (data) {
                        try {
                            state[name] = data;
                        } catch (err) {
                            console.error(err.message);
                        }
                    }
                });

                let deviceType = '';

                if (_deviceType() === 2) {
                    // ios 版本號是放在 application_version
                    deviceType = 'ios';
                } else {
                    deviceType = "android";
                }

                webApiPost('/api/APP/unit000/member/getMemberInfo', { ...state, login_device: deviceType, eventType: 1 }).then(res => {
                    const { status, data } = res;

                    if (status === 200 && data.status_code == 200 && data.status == "success") {
                        commit("updateMemberData", data.data);
                    }
                });
            }
        },
        logout({ commit }, data) {

            memberDataList.forEach((name) => {
                localStorage.removeItem(name);
            });

            const emptyArr = [
                {
                    key: "serialno",
                    value: "",
                },
                {
                    key: "ValidatedKey",
                    value: "",
                },
                {
                    key: "ExpiredDate",
                    value: "",
                },
                {
                    key: "point",
                    value: 0,
                },
                {
                    key: "is_vip",
                    value: 0,
                },
                {
                    key: "coupon_num",
                    value: 0,
                },
                {
                    key: "is_course_member",
                    value: 0,
                },
                {
                    key: "is_daily_member",
                    value: 0,
                },
                {
                    key: "master_star",
                    value: "",
                },
                {
                    key: "member_type",
                    value: "",
                },
                {
                    key: "newsletter",
                    value: "",
                },
                {
                    key: "nick_name",
                    value: "",
                },
                {
                    key: "purchase_record",
                    value: "",
                },
                {
                    key: "zodiac_sign",
                    value: "",
                },
                {
                    key: "member_type_str",
                    value: "一般會員",
                },
                {
                    key: "vip_expire_date",
                    value: "",
                },
                {
                    key: "destiny_analysis",
                    value: "",
                },
                {
                    key: "fortune_analysis",
                    value: "",
                },
                {
                    key: "career_analysis",
                    value: "",
                },
                {
                    key: "emotion_analysis",
                    value: "",
                },
                {
                    key: "relationship_analysis",
                    value: "",
                },
                {
                    key: "vip_attachment",
                    value: "",
                },
                {
                    key: "tool",
                    value: "",
                },
                {
                    key: "vip_equity",
                    value: "",
                },
                {
                    key: "destiny_current_state",
                    value: "",
                },
                {
                    key: "emotion_type",
                    value: "",
                },
                {
                    key: "child_type",
                    value: "",
                },
                {
                    key: "isex",
                    value: 0,
                },
                {
                    key: "birthday_day",
                    value: "",
                },
                {
                    key: "birthday_hour",
                    value: "",
                },
                {
                    key: "birthday_month",
                    value: "",
                },
                {
                    key: "birthday_year",
                    value: "",
                },
                {
                    key: "birthday_calendar",
                    value: "",
                },
                {
                    key: "birthday_LeapMonth",
                    value: "",
                },
                {
                    key: 'first_time_install',
                    value: 1,
                },
                {
                    key: 'ga_data',
                    value: '',
                },
                {
                    key: 'appier_id',
                    value: '',
                }
            ];

            commit("update", emptyArr);

            _deleteWebCookies();
        },
    },
    mutations: {
        updateMemberData(state, data) {
            console.log('updateMemberData');
            memberDataList.forEach((name) => {
                if (data[name] !== undefined) {
                    state[name] = data[name];
                    if (typeof state[name] === 'object') {
                        localStorage.setItem(name, JSON.stringify(data[name]));
                    } else {
                        localStorage.setItem(name, data[name]);
                    }
                }
            });
        },
        update(state, data) {
            if (data.length) {
                data.forEach((item) => {
                    state[item.key] = item.value;
                });
                return;
            }
            const { key, value } = data;
            state[key] = value;
        },
        init(state) {
            memberDataList.forEach((name) => {
                let data = localStorage.getItem(name);
                if (data) {
                    try {
                        state[name] = data;
                    } catch (err) {
                        console.error(err.message);
                    }
                }
            });
        },
    },
}

