import { computed, ref, onMounted, reactive, inject } from "vue";
import { baseGet, webApiGet } from "@/js/services/baseService";
import axios from "axios";
export default {
  props: {
    header: String
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var confirmRedirect = inject("confirmRedirect");
    var refreshMemberInfo = inject("refreshMemberInfo");

    var _refreshMemberInfo = refreshMemberInfo(),
        isPro = _refreshMemberInfo.isPro,
        isLogin = _refreshMemberInfo.isLogin,
        memberInfo = _refreshMemberInfo.memberInfo;

    var pageData = reactive({
      week: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
      date: '',
      zodiac: '',
      yi: '',
      ji: '',
      lunar: '',
      hello: '',
      who: '你好，'
    });
    webApiGet('/api/APP/ecal/getTodayDateInfo').then(function (res) {
      var info = res.data.data;
      pageData.date = info.solar_date.substring(0, 8);
      pageData.zodiac = info.lunar_date.match(/\[(\S*)\]/)[1];
      pageData.lunar = info.lunar_date.match(/\](\S*)/)[1];
      pageData.yi = info.yi;
      pageData.ji = info.ji;
    });
    webApiGet('/api/APP/index/item00001/getResult.php').then(function (res) {
      pageData.hello = res.data.calldata;
    });
    return {
      pageData: pageData,
      confirmRedirect: confirmRedirect,
      memberInfo: memberInfo
    };
  }
};