import { computed, ref, onMounted, reactive, inject } from "vue";
import { baseGet, webApiGet } from "@/js/services/baseService";
export default {
  props: {
    header: String,
    isSpecialHide: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var confirmRedirect = inject("confirmRedirect");
    var showNum = 3;
    var formData = reactive({
      cmsData: [],
      list: []
    }); // 從後台設置幾個區塊的標題

    baseGet("/api_front_page/zhang", {}).then(function (res) {
      formData.cmsData = res.data.res_data.zhang;
    });
    webApiGet('/api/APP/articles/sam_latest.php').then(function (res) {
      formData.list = res.data;
    });
    return {
      confirmRedirect: confirmRedirect,
      formData: formData,
      showNum: showNum
    };
  }
};