import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_f7_link = _resolveComponent("f7-link");

  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.toolbarList, function (item, index) {
    return _openBlock(), _createBlock(_component_f7_link, {
      class: "toolbar-link",
      key: index,
      "tab-link": "#tab-".concat(item.key),
      "tab-link-active": item.key === $props.page,
      href: "/".concat(item.key)
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(item.value), 1)];
      }),
      _: 2
    }, 1032, ["tab-link", "tab-link-active", "href"]);
  }), 128);
}