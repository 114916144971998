const CONST_BASE = {
    locale:{
        EN: 'en',
        TW: 'tw'
    },
    // 驗證
    csrToken:{
        INVALID: -99,
    },
    token:{
        INVALID: -98,
    },
}

const CONST_PROD = {
}

const CONST_DEV = {
}


const CONST = process.env.NODE_ENV == 'development' ? $.extend(true, {}, CONST_BASE, CONST_DEV) :  $.extend(true, {}, CONST_BASE, CONST_PROD);

export default CONST