import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0245e525"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "red"
};
var _hoisted_2 = {
  key: 0,
  class: "display-value"
};
var _hoisted_3 = ["value", "name"];
var _hoisted_4 = ["value", "name"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_f7_icon = _resolveComponent("f7-icon");

  var _component_f7_list_input = _resolveComponent("f7-list-input");

  return _openBlock(), _createBlock(_component_f7_list_input, {
    class: "base-select",
    "floating-label": "",
    input: false,
    value: $props.modelValue
  }, {
    "inner-start": _withCtx(function () {
      return [$props.icon || $props.f7 ? (_openBlock(), _createBlock(_component_f7_icon, {
        key: 0,
        class: "start-icon",
        icon: $props.icon,
        f7: $props.f7,
        onClick: $setup.clickInput
      }, null, 8, ["icon", "f7", "onClick"])) : _createCommentVNode("", true)];
    }),
    "inner-end": _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "inner-end", {}, undefined, true)];
    }),
    label: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($props.placeholder) + " ", 1), $props.isRequired ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*")) : _createCommentVNode("", true)];
    }),
    input: _withCtx(function () {
      return [$props.displayValues ? (_openBlock(), _createElementBlock("label", _hoisted_2, [_createTextVNode(_toDisplayString($setup.displayValue) + " ", 1), _createElementVNode("input", {
        class: "input-with-value",
        type: "text",
        readonly: "",
        ref: "inputEl",
        value: $props.modelValue,
        onChange: _cache[0] || (_cache[0] = function () {
          return $setup.emitVal && $setup.emitVal.apply($setup, arguments);
        }),
        name: $props.name
      }, null, 40, _hoisted_3)])) : (_openBlock(), _createElementBlock("input", {
        key: 1,
        class: "input-with-value",
        type: "text",
        readonly: "",
        ref: "inputEl",
        value: $props.modelValue,
        onChange: _cache[1] || (_cache[1] = function () {
          return $setup.emitVal && $setup.emitVal.apply($setup, arguments);
        }),
        name: $props.name
      }, null, 40, _hoisted_4))];
    }),
    _: 3
  }, 8, ["value"]);
}