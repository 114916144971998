import { reactive, ref, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useForm } from "vee-validate";
import { webApiPost } from "@/js/services/baseService";
import { useStore } from "vuex";
import { email } from "@vee-validate/rules";
export default {
  components: {},
  props: {
    f7router: Object,
    redirectPage: {
      type: [String],
      default: ""
    }
  },
  setup: function setup(props) {
    var appToken = ref("");

    _get_mobile_info().then(function (res) {
      if (res) {
        appToken.value = res.token;
      }
    });

    var confirmRedirect = inject("confirmRedirect");
    var myFortuneCheck = inject("myFortuneCheck"); // 多國語系

    var _useI18n = useI18n({
      messages: {
        tw: {},
        en: {}
      }
    }),
        t = _useI18n.t,
        locale = _useI18n.locale;

    var f7router = props.f7router,
        redirectPage = props.redirectPage;
    var loginBtnDisable = ref(false); // form驗證設定

    var _useForm = useForm({
      validateOnMount: false,
      validationSchema: {
        account: "required",
        password: "required"
      },
      initialValues: {
        account: "",
        password: ""
      }
    }),
        errors = _useForm.errors,
        isSubmitting = _useForm.isSubmitting,
        submitCount = _useForm.submitCount,
        meta = _useForm.meta,
        values = _useForm.values,
        setFieldError = _useForm.setFieldError,
        setErrors = _useForm.setErrors,
        setFieldValue = _useForm.setFieldValue,
        setValues = _useForm.setValues,
        validate = _useForm.validate,
        validateField = _useForm.validateField,
        handleSubmit = _useForm.handleSubmit,
        submitForm = _useForm.submitForm,
        handleReset = _useForm.handleReset,
        resetForm = _useForm.resetForm;

    var store = useStore();
    var dispatch = store.dispatch,
        commit = store.commit,
        getters = store.getters;
    var refreshMemberInfo = inject("refreshMemberInfo");
    var deviceType = ref("");

    switch (_deviceType()) {
      case 1:
        deviceType.value = "android";
        break;

      case 2:
        deviceType.value = "ios";
        break;
    }

    console.log('deviceType', deviceType.value); // 驗證帳號

    var onFormSubmit = handleSubmit(function (values, actions) {
      var account = values.account,
          password = values.password;
      var postData = {
        account: account,
        password: password,
        keep_login: 1,
        login_device: deviceType.value,
        app_id: appToken.value
      };
      loginBtnDisable.value = true;
      webApiPost("/api/APP/unit000/member/login", postData).then(function (res) {
        var status = res.status,
            data = res.data;

        if (status === 200 && data.status_code == 200 && data.status == "success") {
          dispatch("auth/login", data.data).then(function (res) {
            loginCheck();
          });
        } else {
          f7.dialog.confirm(data.msg, "", function () {});
        }
      });
    }); // email, id, name, picture

    var checkThirtPartyLogin = function checkThirtPartyLogin(res) {
      var channel_id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";

      _loading_start();

      var checkObj = {
        AppId: appToken.value,
        app_id: appToken.value,
        login_device: deviceType.value,
        statusMessage: "",
        channel_id: channel_id,
        name: res.name,
        pictureUrl: res.picture,
        user_id: res.id,
        email: res.email
      };
      webApiPost("/api/APP/unit000/member/thirdpartyLogin", checkObj).then(function (res) {
        _loading_stop();

        var status = res.status,
            data = res.data;

        if (status === 200 && data.status_code == 200 && data.status == "success") {
          dispatch("auth/login", data.data).then(function (res) {
            loginCheck();
          });
        } else {
          confirmRedirect("", "https://wwwstage.click108.com.tw/api/APP/thirdparty/bundle.php", false, checkObj);
        }
      }).catch(function () {
        _loading_stop();
      });
    };

    var loginCheck = function loginCheck() {
      var doRedirect = function doRedirect() {
        appGtag('event', 'login');
        localStorage.setItem("appLogin", 1);
        m_refresh_page("/");
      };

      if (props.redirectPage) {
        switch (props.redirectPage) {
          case "my_fortune_check":
            doRedirect = function doRedirect() {
              myFortuneCheck();
            };

            break;
        }

        dispatch("auth/setMemberInfo").then(function (res) {
          var waitUntil = setInterval(function () {
            if (localStorage.getItem("serialno")) {
              if (localStorage.getItem("nick_name") != "") {
                doRedirect();
                clearInterval(waitUntil);
              }
            } else {
              doRedirect();
              clearInterval(waitUntil);
            }
          }, 100);
        });
      } else {
        doRedirect();
      }
    };

    return {
      t: t,
      values: values,
      onFormSubmit: onFormSubmit,
      confirmRedirect: confirmRedirect,
      loginBtnDisable: loginBtnDisable,
      _is_ios_13: _is_ios_13,
      _login_fb: _login_fb,
      _login_line: _login_line,
      _login_google: _login_google,
      _login_apple: _login_apple,
      _back_page: _back_page,
      checkThirtPartyLogin: checkThirtPartyLogin
    };
  }
};