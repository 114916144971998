import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-184aa1c6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "header"
};
var _hoisted_2 = {
  key: 0,
  class: "header-other"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h4", {
    class: _normalizeClass(_defineProperty({
      'header-title': true
    }, "header-".concat($props.headerColor), $props.headerColor)),
    style: _normalizeStyle({
      color: $props.color
    })
  }, _toDisplayString($props.title), 7), _ctx.$slots.default ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])) : _createCommentVNode("", true)]);
}