import { computed, ref, onMounted, reactive, inject } from "vue";
import { baseGet, basePost, Store } from "@/js/services/baseService";
export default {
  props: {
    header: String
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var redirectTo = inject("redirectTo");
    var pageData = inject("brandLink");
    return {
      pageData: pageData,
      redirectTo: redirectTo,
      _openSocialApp: _openSocialApp
    };
  }
};