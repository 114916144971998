import { inject } from 'vue';
export default {
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var confirmRedirect = inject('confirmRedirect');

    var goto = function goto(url) {
      confirmRedirect('', url);
    };

    return {
      goto: goto
    };
  }
};