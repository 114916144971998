import { computed, provide, ref, inject } from "vue";
import store from "../store/store.js";
import {
    webApiPost,
    basePost,
    baseGet,
} from "@/js/services/baseService";
import Home from "@/pages/Home";
import Login from "@/pages/Login.vue";
import NotFoundPage from "@/pages/404.vue";
import Instructions from "@/pages/Instructions";

function firstTimeInstall({ to, from, resolve, reject }) {
    if (!localStorage.getItem("first_time_install")) {
        localStorage.setItem("first_time_install", "1");
        m_refresh_page('/instructions');
    }
    resolve();
}

function goFixPage({ to, from, resolve, reject }) {
    // 解析一個日期字串
    const dateString = "2024-03-14 02:00:00";
    const milliseconds = Date.parse(dateString);

    const dateString2 = "2024-03-14 04:00:00";
    const milliseconds2 = Date.parse(dateString2);

    // 判定時間是否在今天之前
    if (milliseconds <= Date.now() && Date.now()  <= milliseconds2) {
        m_refresh_page('/fix');
    }else{
        resolve();
    }
}

function gaPageView({ to, from, resolve, reject }) {
    let config = { page_title: to.route.path_name };

    config.page_location = location.protocol + '//' + location.hostname + to.path;

    if (localStorage.getItem('serialno')) {
        if (localStorage.getItem('ga_data')) {
            let user_properties = JSON.parse(localStorage.getItem('ga_data'));
            appGtag('set', 'user_id', { "user_id": user_properties.user_id });
            appGtag('set', 'user_properties', user_properties);
        }
        if(localStorage.getItem('appier_id')) {
            appGtag('set', 'appier_id', { "appier_id": localStorage.getItem('appier_id') });
        }
    }

    appGtag('event', 'page_view', config);

    resolve();
}

function setMemberInfo({ to, from, resolve, reject }) {
    const { commit, state, dispatch, getters } = store;
    if (localStorage.getItem('serialno')) {
        dispatch("auth/setMemberInfo").then((res) => {
            const waitUntil = setInterval(function () {
                if (localStorage.getItem('serialno')) {
                    if (localStorage.getItem("nick_name") != '') {
                        clearInterval(waitUntil);
                        resolve();
                    }
                } else {
                    clearInterval(waitUntil);
                    resolve();
                }
            }, 100)
        });
    } else {
        resolve();
    }
}

var routes = [
    {
        path: "/",
        path_name: "APP 首頁",
        async: function ({ app, to, resolve }) {
            resolve({
                component: Home
            });
            return;
        },
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, firstTimeInstall, setMemberInfo],
    },
    {
        path: "/fix",
        path_name: "APP 維修中",
        asyncComponent: () => import("@/pages/Fix"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [],
    },
    // 紫微斗數
    {
        path: "/ziwei",
        path_name: "APP 紫微斗數",
        asyncComponent: () => import("@/pages/Ziwei"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // 星座塔羅
    {
        path: "/zodiac_signs",
        path_name: "APP 星座塔羅",
        asyncComponent: () => import("@/pages/ZodiacSigns"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // 算命占卜
    {
        path: "/fortune",
        path_name: "APP 算命占卜",
        asyncComponent: () => import("@/pages/Fortune"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // 日本命理
    {
        path: "/japan",
        path_name: "APP 日本命理",
        asyncComponent: () => import("@/pages/Japan"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // 農民曆
    {
        path: "/lunar_calendar",
        path_name: "APP 農民曆",
        asyncComponent: () => import("@/pages/LunarCalendar"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // 開運祈福
    {
        path: "/pray",
        path_name: "APP 開運祈福",
        asyncComponent: () => import("@/pages/Pray"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // 文章心測
    {
        path: "/psychological",
        path_name: "APP 文章心測",
        asyncComponent: () => import("@/pages/Psychological"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // 免費算命
    {
        path: "/free",
        path_name: "APP 免費算命",
        asyncComponent: () => import("@/pages/Free"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },

    // 好運8
    {
        path: "/luck8",
        path_name: "APP 好運8",
        asyncComponent: () => import("@/pages/Luck8"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },

    // 我的運勢
    {
        path: "/my_fortune",
        path_name: "APP 我的運勢",
        asyncComponent: () => import("@/pages/MyFortune"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // 會員中心
    {
        path: "/member",
        path_name: "APP 會員中心",
        asyncComponent: () => import("@/pages/Member"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // 登入
    {
        path: "/login",
        async: function ({ app, to, resolve }) {
            // Requested route
            const ValidatedKey = localStorage.getItem("ValidatedKey") || "";

            let isLoggedIn = ValidatedKey ? true : false;

            // android 版本的會把首次登入的憑證刪掉，要補上
            const setFirstTimeInstall = setInterval(function () {
                if (!localStorage.getItem("first_time_install")) {
                    localStorage.setItem("first_time_install", "1");
                } else {
                    clearInterval(setFirstTimeInstall);
                }
            }, 500)

            if (isLoggedIn === true) {
                m_refresh_page("/");
            } else {
                resolve({
                    component: Login
                });
            }
        },
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [setMemberInfo],
    },
    // App 操作說明
    {
        path: "/instructions",
        path_name: "APP 操作說明",
        asyncComponent: () => import("@/pages/Instructions"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // 版權宣告
    {
        path: "/copyright",
        path_name: "APP 版權宣告",
        asyncComponent: () => import("@/pages/Copyright"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // 收件夾
    {
        path: "/message",
        path_name: "APP 收件夾",
        asyncComponent: () => import("@/pages/Message"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // 收件夾
    {
        path: "/message_detail",
        path_name: "APP 收件夾內頁",
        asyncComponent: () => import("@/pages/MessageDetail"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // loading
    {
        path: "/loading",
        asyncComponent: () => import("@/pages/Loading"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [setMemberInfo],
    },
    // 我的運勢檢測頁
    {
        path: "/my_fortune_check",
        asyncComponent: () => import("@/pages/MyFortuneCheck"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [setMemberInfo],
    },
    // 個人現況
    {
        path: "/personal_status",
        path_name: "APP 個人現況",
        asyncComponent: () => import("@/pages/PersonalStatus"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [gaPageView, setMemberInfo],
    },
    // 購買點數
    {
        path: "/pay",
        path_name: "購買點數",
        asyncComponent: () => import("@/pages/Pay"),
        options: {
            transition: "f7-router-fade",
        },
        beforeEnter: [setMemberInfo],
    },
    {
        path: "(.*)",
        async: function ({ app, to, resolve }) {
            if (to.url.includes('http')) {
                let url = to.url.substr(to.url.indexOf('http'));

                if (to.url.indexOf('https://') == -1) {
                    url = url.replace('https:/', 'https://');
                }

                if (to.url.indexOf('http://') == -1) {
                    url = url.replace('http:/', 'http://');
                }
                basePost("/api_common/crypt", {
                    serialno: computed(() => {
                        return localStorage.getItem("serialno");
                    }).value,
                    ValidatedKey: computed(() => {
                        return localStorage.getItem("ValidatedKey");
                    }).value,
                    ExpiredDate: computed(() => {
                        return localStorage.getItem("ExpiredDate");
                    }).value,
                    AppId: computed(() => {
                        return localStorage.getItem("app_id");
                    }).value,
                    redirectUrl: url,
                    deviceType: computed(() => {
                        return (_deviceType() === 2 ? 'ios' : 'android');
                    }).value,
                }).then((res) => {
                    // console.log(res.data.res_data);
                    openWindow(res.data.res_data);
                });
            } else {
                resolve({
                    component: NotFoundPage
                });
            }
        },
        // beforeEnter: [setMemberInfo],
    },
];

export default routes;
