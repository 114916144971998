import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "prevArrow"
};
var _hoisted_2 = {
  class: "nextArrow"
};
var _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_f7_icon = _resolveComponent("f7-icon");

  var _component_f7_link = _resolveComponent("f7-link");

  var _component_f7_swiper_slide = _resolveComponent("f7-swiper-slide");

  var _component_f7_swiper = _resolveComponent("f7-swiper");

  return _openBlock(), _createBlock(_component_f7_swiper, {
    class: _normalizeClass({
      banner: true,
      full: $props.full
    }),
    loop: "",
    navigation: {
      nextEl: '.nextArrow',
      prevEl: '.prevArrow'
    },
    pagination: {
      modifierClass: 'banner-pagination-',
      type: 'fraction'
    },
    initialSlide: 1,
    autoplay: {
      delay: 5000
    }
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_f7_icon, {
        f7: "chevron_left"
      })]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_f7_icon, {
        f7: "chevron_right"
      })]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.pageData.list, function (img, index) {
        return _openBlock(), _createBlock(_component_f7_swiper_slide, {
          key: index
        }, {
          default: _withCtx(function () {
            return [_createVNode(_component_f7_link, {
              onClick: function onClick($event) {
                return $setup.confirmRedirect('', img.url);
              }
            }, {
              default: _withCtx(function () {
                return [_createElementVNode("img", {
                  src: img.pic_src,
                  alt: "banner"
                }, null, 8, _hoisted_3)];
              }),
              _: 2
            }, 1032, ["onClick"])];
          }),
          _: 2
        }, 1024);
      }), 128))];
    }),
    _: 1
  }, 8, ["class", "navigation"]);
}