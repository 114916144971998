import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "panel-wrap-el"
};
var _hoisted_2 = {
  class: "toolbar-inner"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_f7_page = _resolveComponent("f7-page");

  var _component_f7_panel = _resolveComponent("f7-panel");

  return _openBlock(), _createElementBlock(_Fragment, null, [$options.opened ? (_openBlock(), _createElementBlock("div", _hoisted_1)) : _createCommentVNode("", true), $options.opened ? (_openBlock(), _createBlock(_component_f7_panel, {
    key: 1,
    class: "top-panel",
    right: "",
    cover: "",
    backdrop: "",
    "container-el": ".panel-wrap-el",
    opened: $options.opened,
    "onUpdate:opened": _cache[1] || (_cache[1] = function ($event) {
      return $options.opened = $event;
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_f7_page, null, {
        default: _withCtx(function () {
          return [_createElementVNode("div", {
            class: "panel-bg",
            onClick: _cache[0] || (_cache[0] = function ($event) {
              return $options.opened = false;
            })
          }), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")])];
        }),
        _: 3
      })];
    }),
    _: 3
  }, 8, ["opened"])) : _createCommentVNode("", true)], 64);
}