import { computed, ref, onMounted, reactive } from "vue";
export default {
  components: {},
  props: {},
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var formData = reactive({
      name: "",
      birthday: "",
      time: "",
      gender: "woman"
    });

    var changeGender = function changeGender() {
      formData.gender = formData.gender == 'woman' ? 'man' : 'woman';
    };

    return {
      formData: formData,
      changeGender: changeGender
    };
  }
};