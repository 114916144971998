import { f7 } from 'framework7-vue';
import { ref, onMounted, computed, toRef, watch } from 'vue';
import { useField } from 'vee-validate';
export default {
  name: 'BaseSelect',
  emits: ['ref', 'update:modelValue'],
  props: {
    // for icon
    icon: String,
    f7: String,
    placeholder: String,
    modelValue: [String, Number],
    name: String,
    pickerTitle: String,
    pickerValues: {
      type: Array
    },
    displayValues: Array,
    isRequired: {
      type: Boolean,
      default: true
    },
    isGold: {
      type: Boolean,
      default: false
    },
    formatValue: {
      type: Function,
      default: null
    },
    canPickerValues: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var inputEl = ref();
    var modelValue = toRef(props, 'modelValue');
    var displayValues = toRef(props, 'displayValues');
    var name = props.name,
        pickerValues = props.pickerValues,
        pickerTitle = props.pickerTitle; // validate

    var _useField = useField(name),
        errorMessage = _useField.errorMessage,
        thisName = _useField.name;

    function emitVal(e) {
      emit('update:modelValue', e.target.value);
    }

    var pickerSetting = {};
    var cols = [{
      textAlign: 'center',
      values: pickerValues
    }];
    var displayValue = computed(function () {
      if (!displayValues.value) return '';
      var index = pickerValues.indexOf("".concat(modelValue.value));
      return displayValues.value[index];
    });
    var displayValuesChange = false;

    if (displayValues.value) {
      cols[0].displayValues = displayValues.value;
      watch(displayValues, function (displayValues) {
        displayValuesChange = true;
        pickerSetting.cols[0].displayValues = displayValues;
      });
    }

    var pickerValuesChange = false;

    if (props.canPickerValues) {
      watch(function () {
        return props.pickerValues;
      }, function (pickerValues) {
        pickerValuesChange = true;
        cols[0].values = pickerValues;
        pickerSetting.cols[0].values = pickerValues;
      });
    }

    if (name === 'birthday') {
      cols = [{
        values: function () {
          var arr = [];

          for (var i = 1901; i <= 2030; i++) {
            arr.push("".concat(i, "\u5E74"));
          }

          return arr;
        }()
      }, {
        values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(function (item) {
          return "".concat(item, "\u6708");
        })
      }, {
        values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].map(function (item) {
          return "".concat(item, "\u65E5");
        })
      }];
    }

    var modalTemplate = "\n            <div class=\"sheet-modal picker-sheet-modal ".concat(props.isGold ? 'is-gold' : '', "\" style=\"height:auto;\">\n                <div class=\"sheet-modal-inner\" >\n                    <div class=\"picker-sheet-modal__toolbar\">\n                        <div class=\"row\">\n                            <div class=\"col-20 left\">\n                            </div>\n                            <div class=\"col-60 title\">\n                                <div class=\"picker-sheet-modal__title\">\n                                    ").concat(pickerTitle, "\n                                </div>\n                            </div>\n                            <div class=\"col-20 right\">\n                                <a href=\"#\" class=\"link picker-sheet-modal__close\">\n                                    <i class=\"icon-pickerclose\"></i>\n                                </a>\n                            </div>\n                        </div>\n                    </div>\n                <div class=\"sheet-picker-place\"></div>\n                <a href=\"javascript:;\" class=\"picker-sheet-modal__btn\">\u78BA \u8A8D</a>\n            </div>\n        ");
    onMounted(function () {
      var init = false;
      var picker;
      var dynamicSheet = f7.sheet.create({
        content: modalTemplate,
        backdrop: true,
        on: {
          open: function open(sheet) {
            if (picker && displayValuesChange) {
              displayValuesChange = false;
              var pickerCatch = picker.getValue();
              picker.cols[0].replaceValues(pickerValues, displayValues.value);
              picker.setValue(pickerCatch);
            }

            if (picker && pickerValuesChange) {
              pickerValuesChange = false;
              var pickerCatch = picker.getValue();
              picker.cols[0].replaceValues(props.pickerValues, props.pickerValues);
              picker.setValue(pickerCatch);
            }

            if (!init) {
              init = true;

              if (props.formatValue) {
                pickerSetting.formatValue = props.formatValue;
              }

              if (name === 'birthday') {
                pickerSetting.value = ['1990年', '1月', '1日'];
                pickerSetting.on = {
                  change: function change(picker, values, displayValues) {
                    var year = parseInt(picker.value[0]);
                    var month = parseInt(picker.value[1]);
                    var day = parseInt(picker.value[2]);
                    var daysInMonth = new Date(year, month, 0).getDate(); // 把超過的天數隱藏

                    for (var index = 0; index < 3; index++) {
                      var i = 31 - index;

                      if (i > daysInMonth) {
                        picker.cols[2].items[i - 1].style.display = 'none';
                      } else {
                        picker.cols[2].items[i - 1].style.display = 'block';
                      }
                    }

                    if (day > daysInMonth) {
                      picker.cols[2].setValue("".concat(daysInMonth, "\u65E5"));
                    }
                  }
                };
              }

              picker = f7.picker.create(pickerSetting);
              sheet.$el.find('.picker-sheet-modal__close').on('click', function () {
                sheet.close();
              });
              sheet.$el.find('.picker-sheet-modal__btn').on('click', function () {
                sheet.close();
              });
            }
          }
        }
      });
      pickerSetting = {
        inputEl: inputEl.value,
        rotateEffect: true,
        backdrop: true,
        cols: cols,
        toolbar: false,
        containerEl: dynamicSheet.$el.find('.sheet-picker-place')[0]
      };
      var pickerIpt = $(pickerSetting.inputEl);

      if (pickerIpt.length) {
        pickerIpt.on('click', function () {
          dynamicSheet.open();
        });
      }
    });

    function clickInput() {
      inputEl.value.click();
    }

    return {
      inputEl: inputEl,
      emitVal: emitVal,
      errorMessage: errorMessage,
      displayValue: displayValue,
      clickInput: clickInput
    };
  }
};