import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_f7_block = _resolveComponent("f7-block");

  var _component_HomeFooterBlock = _resolveComponent("HomeFooterBlock");

  var _component_f7_page = _resolveComponent("f7-page");

  return _openBlock(), _createBlock(_component_f7_page, {
    name: "home"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.blockSort, function (item, index) {
        return _openBlock(), _createBlock(_component_f7_block, {
          key: index,
          class: _normalizeClass(item.class)
        }, {
          default: _withCtx(function () {
            return [(_openBlock(), _createBlock(_resolveDynamicComponent(item.component), _mergeProps(item.bind, {
              class: {
                'special-space': item.specialSpace
              }
            }), null, 16, ["class"]))];
          }),
          _: 2
        }, 1032, ["class"]);
      }), 128)), _createVNode(_component_f7_block, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_HomeFooterBlock)];
        }),
        _: 1
      })];
    }),
    _: 1
  });
}