import { inject, computed } from "vue";
export default {
  name: "TheMenu",
  props: {
    list: Array
  },
  setup: function setup() {
    var refreshMemberInfo = inject("refreshMemberInfo");

    var _refreshMemberInfo = refreshMemberInfo(),
        isPro = _refreshMemberInfo.isPro,
        isLogin = _refreshMemberInfo.isLogin,
        memberInfo = _refreshMemberInfo.memberInfo,
        isSpecialHide = _refreshMemberInfo.isSpecialHide;

    var myFortuneCheck = inject("myFortuneCheck");
    var pageData = inject("brandLink");
    var redirectTo = inject("redirectTo");

    function format_with_substring(number) {
      var arr = (number + "").split(".");
      var int = arr[0] + "";
      var fraction = arr[1] || "";
      var f = int.length % 3;
      var r = int.substring(0, f);

      for (var i = 0; i < Math.floor(int.length / 3); i++) {
        r += "," + int.substring(f + i * 3, f + (i + 1) * 3);
      }

      if (f === 0) {
        r = r.substring(1);
      }

      return r + (!!fraction ? "." + fraction : "");
    }

    var point = computed(function () {
      return format_with_substring(memberInfo.point);
    });
    return {
      pageData: pageData,
      redirectTo: redirectTo,
      m_refresh_page: m_refresh_page,
      isLogin: isLogin,
      isPro: isPro,
      memberInfo: memberInfo,
      myFortuneCheck: myFortuneCheck,
      _openSocialApp: _openSocialApp,
      point: point,
      isSpecialHide: isSpecialHide
    };
  }
};