export default {
  props: {
    isOpened: Boolean
  },
  emits: ['update:isOpened'],
  computed: {
    opened: {
      get: function get() {
        return this.isOpened;
      },
      set: function set(val) {
        this.$emit('update:isOpened', val);
      }
    }
  }
};