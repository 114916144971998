import { onMounted, reactive, ref, computed, shallowRef, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { baseGet, basePost, Store } from "@/js/services/baseService";
import BannerBlock from "@projectComponents/BannerBlock";
import YellowCalendarBlock from "@projectComponents/YellowCalendarBlock";
import HotWeekBlock from "@projectComponents/HotWeekBlock";
import MemberOnlyBlock from "@projectComponents/MemberOnlyBlock";
import CelebrityBlock from "@projectComponents/CelebrityBlock";
import BrandLinkBlock from "@projectComponents/BrandLinkBlock";
import EverydayFortuneBlock from "@projectComponents/EverydayFortuneBlock";
import OnlineCoursesBlock from "@projectComponents/OnlineCoursesBlock";
import HomeFooterBlock from "@projectComponents/HomeFooterBlock";
export default {
  setup: function setup() {
    // 後端取得 mobile info 用
    var refreshMemberInfo = inject("refreshMemberInfo");

    var _refreshMemberInfo = refreshMemberInfo(),
        isPro = _refreshMemberInfo.isPro,
        isLogin = _refreshMemberInfo.isLogin,
        memberInfo = _refreshMemberInfo.memberInfo,
        isSpecialHide = _refreshMemberInfo.isSpecialHide;

    var updateUnReadMessageNum = inject("updateUnReadMessageNum");
    updateUnReadMessageNum();
    var confirmRedirect = inject("confirmRedirect"); // 多國語系

    var _useI18n = useI18n({
      messages: {
        tw: {},
        en: {}
      }
    }),
        t = _useI18n.t,
        locale = _useI18n.locale;

    var pageData = reactive({
      isOpened: false
    });
    var homeBlock = {
      BannerBlock: {
        name: "banner",
        component: shallowRef(BannerBlock),
        bind: {
          full: true,
          header: "橫幅管理",
          bannerType: "Home"
        }
      },
      OnlineCoursesBlock: {
        component: shallowRef(OnlineCoursesBlock),
        bind: {
          header: "線上課程"
        }
      },
      YellowCalendarBlock: {
        component: shallowRef(YellowCalendarBlock),
        bind: {
          header: "今日黃曆"
        }
      },
      HotWeekBlock: {
        component: shallowRef(HotWeekBlock),
        bind: {
          header: "每週熱門",
          list: []
        },
        class: "is-slick no-shadow"
      },
      EverydayFortuneBlock: {
        component: shallowRef(EverydayFortuneBlock),
        bind: {
          header: "天天免費算"
        }
      },
      MemberOnlyBlock: {
        component: shallowRef(MemberOnlyBlock),
        bind: {
          header: "會員獨享"
        }
      },
      CelebrityBlock: {
        component: shallowRef(CelebrityBlock),
        bind: {
          header: "張盛舒"
        }
      },
      BrandLinkBlock: {
        component: shallowRef(BrandLinkBlock),
        bind: {
          header: "品牌連結"
        }
      }
    };
    onMounted(function () {
      if (isLogin.value) {
        _get_variable("UID").then(function (res) {
          if (res["res_content"] == undefined || res["res_content"] == "") {
            // 變更資料庫
            var waitUntilNum = 0;
            var waitUntil = setInterval(function () {
              waitUntilNum += 1;

              if (localStorage.getItem('serialno')) {
                if (localStorage.getItem('appier_id')) {
                  appLogin(localStorage.getItem('appier_id'));
                  clearInterval(waitUntil);
                }
              }

              if (waitUntilNum > 5) {
                clearInterval(waitUntil);
              }
            }, 1000);
          }
        }); // if (localStorage.getItem("appLogin") == 1) {
        //     var waitUntilNum = 0;
        //     const waitUntil = setInterval(function () {
        //         waitUntilNum += 1;
        //         if (localStorage.getItem('serialno')) {
        //             if (localStorage.getItem('appier_id')) {
        //                 appLogin(localStorage.getItem('appier_id'));
        //                 clearInterval(waitUntil);
        //             }
        //         }
        //         if (waitUntilNum > 5) {
        //             clearInterval(waitUntil);
        //         }
        //     }, 1000);
        // }

      }
    });

    var isSpecialSpace = function isSpecialSpace(item, index) {
      if (index === 0) item.specialSpace = true;
      return item;
    };

    var blockSort = reactive([]); // 從後台設置幾個區塊的標題

    baseGet("/api_front_page/front_page", {}).then(function (res) {
      console.log('res', res.data.res_data);
      Object.keys(res.data.res_data).map(function (index) {
        console.log('index', index);
        if (index === 'BannerBlock' && isSpecialHide.value) return;
        if (index === 'MemberOnlyBlock' && isSpecialHide.value) return;

        if (index === 'CelebrityBlock' && isSpecialHide.value) {
          homeBlock[index].bind.isSpecialHide = true;
        }

        homeBlock[index].bind.header = res.data.res_data[index].name;
        blockSort.push(homeBlock[index]);
      });
      blockSort = blockSort.map(isSpecialSpace);
    });

    if (location.search) {
      var nowUrl = new URL(location.href);

      if (nowUrl.searchParams.has("go_page")) {
        var page = nowUrl.searchParams.get("go_page");
        updateUrl("go_page", "used");
        var notice_id = 0;

        switch (page) {
          case "notice_detail":
            notice_id = nowUrl.searchParams.get("notice_id");
            m_refresh_page("/message_detail", {
              notice_id: notice_id
            }, false);
            break;

          case "link":
            notice_id = nowUrl.searchParams.get("notice_id"); // 從後台設置幾個區塊的標題

            baseGet("/api_notice/notice_fcm_link/" + notice_id, {}).then(function (res) {
              confirmRedirect("", res.data.res_data);
            });
            break;
        }
      }
    }

    function updateUrl(key, value) {
      var newurl = updateQueryStringParameter(key, value);
      window.history.replaceState({
        path: newurl
      }, "", newurl);
    }

    function updateQueryStringParameter(key, value) {
      var uri = window.location.href;

      if (!value) {
        return uri;
      }

      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf("?") !== -1 ? "&" : "?";

      if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2");
      } else {
        return uri + separator + key + "=" + value;
      }
    }

    return {
      blockSort: blockSort,
      m_direct_page: m_direct_page,
      pageData: pageData
    };
  }
};